import Vue from 'vue';
import {
    Container,
    Photo,
    Blog,
    BoxGradient,
    HorizontalScroll,
    Tuber,
    CardContainer,
    Row,
    InputText,
    InputTextarea,
    Heading,
    Texture,
    Alert,
    Fab,
} from './../xerneas/src/import';

Vue.component('Container', Container);
Vue.component('Photo', Photo);
Vue.component('Blog', Blog);
Vue.component('BoxGradient', BoxGradient);
Vue.component('HorizontalScroll', HorizontalScroll);
Vue.component('Tuber', Tuber);
Vue.component('CardContainer', CardContainer);
Vue.component('Row', Row);
Vue.component('InputText', InputText);
Vue.component('InputTextarea', InputTextarea);
Vue.component('Heading', Heading);
Vue.component('Texture', Texture);
Vue.component('Alert', Alert);
Vue.component('Fab', Fab);

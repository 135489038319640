<template>
      <svg class="dots" :width="width" :height="height" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
          <!-- Generator: Sketch 3.5.1 (25234) - http://www.bohemiancoding.com/sketch -->
          <desc>Created with Sketch.</desc>
          <defs></defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
              <g class="dots" sketch:type="MSArtboardGroup" :fill="loadingColor">
                  <circle class="dot1" sketch:type="MSShapeGroup" cx="25" cy="30" r="13"></circle>
                  <circle class="dot2" sketch:type="MSShapeGroup" cx="65" cy="30" r="13"></circle>
                  <circle class="dot3" sketch:type="MSShapeGroup" cx="105" cy="30" r="13"></circle>
              </g>
          </g>
      </svg>
</template>

<script>
export default {
  props: {
    loadingColor: {
      type: String,
      default: '#000',
    },
    width: {
      type: String,
      default: '30px',
    },
    height: {
      type: String,
      default: '30px',
    },
  },
};
</script>

<style lang="scss" scoped>
.dots {
  .dot1{
    animation: load 1s infinite;
  }

  .dot2{
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  .dot3{
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }
}

@keyframes load{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
</style>

<template>
    <div class="error-container ">
        <div class="container">
            <center>
                <component :error="error" :is="component" />
            </center>
        </div>
    </div>
</template>

<script>
import defaultComponent from '~/components/errors/default';
import error403 from '~/components/errors/403';

export default {
    components: {
        defaultComponent,
        error403,
    },
    layout: 'theme',
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            component: 'defaultComponent',
        };
    },
    created() {
        this.check();
    },
    methods: {
        check() {
            switch (this.error.statusCode) {
            case 403:
                this.component = 'error403';
                break;

            default:
                this.component = 'defaultComponent';
                break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~/assets/styles/theme/_vars.scss';

    .error-container {
        margin: 60px auto !important;
        .error-card {
            max-width: 364px;
            text-align: center;
            .error-image {
                margin-bottom: 16px;
            }
        }
        .error-item {
            font-size: 200px;
            color: $colorBrandR500 !important;
        }
    }
</style>

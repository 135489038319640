import Vue from 'vue';
import VeeValidate from 'vee-validate';

VeeValidate.Validator.extend('phone', {
    getMessage: field => 'Please enter a valid phone number.',
    validate: value => value.length >= 12 && value.length < 13,
});

Vue.use(VeeValidate, {
    locale: 'en',
    dictionary: {
        en: {
            messages: {
                email: 'Please enter a valid email address.',
            },
        },
    },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loading',{staticClass:"bg white d-flex",class:_vm.classList,attrs:{"no-text":_vm.noText,"position":_vm.position,"styles":{
  backgroundColor: '#FFFF',
  transition: 'all 1s',
  opacity: _vm.loading ? 1 : 0,
  zIndex: _vm.loading ? 100 : -1,
  left: 0,
  top: 0,
  height: _vm.height,
  position: _vm.position
}}})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <footer class="footer p-x-56 p-y-56 p-x-small-28 p-y-sm-60">
        <container size="lg">
            <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-6 only-desktop">
                    <nuxt-link hreflang="en-ca" to="/">
                        <img class="lazyload" :data-src="$image('logo-simple.svg')" width="96" height="24">
                    </nuxt-link>
                    <div class="m-t-24 m-b-80 m-b-small-10 d-flex">
                        <a hreflang="en-ca" href="https://www.instagram.com/peekageapp/" target="_blank" rel="noopener">
                            <span class="icon s s-instagram hover color secondary m-r-16" />
                        </a>
                        <a hreflang="en-ca" href="https://www.facebook.com/peekageApp/" target="_blank" rel="noopener">
                            <span class="icon s s-facebook hover color secondary m-r-16" />
                        </a>
                        <a hreflang="en-ca" href="https://twitter.com/peekageapp" target="_blank" rel="noopener">
                            <span class="icon s s-twitter hover color secondary m-r-16" />
                        </a>
                        <a hreflang="en-ca" href="https://www.linkedin.com/company/peekage" target="_blank" rel="noopener">
                            <span class="icon s s-linkedin hover color secondary" />
                        </a>
                    </div>
                    <p class="font f-s-12 l-h-16 f-w-500 text-color tertiary" style="letter-spacing: -0.3px;">
                        © 2024 Ecounter Solutions Inc.
                        <img class="m-l-30 lazyload" :data-src="$image('arts/techstars-logo.svg')"
                             alt="Techstars Logo"
                             width="68"
                             height="11"
                        >
                    </p>
                </div>
                <div class="col-sm-6 w-50 col-md-3 col-lg-2 menus">
                    <ul class="list-unstyled">
                        <li class="m-b-20 font f-w-700 l-t-s-3 f-s-16 l-h-20">
                            Company
                        </li>
                        <li class="m-b-16 text sm">
                            <nuxt-link hreflang="en-ca" to="/about" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0 font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    About us
                                </h6>
                            </nuxt-link>
                        </li>
                        <li class="m-b-16">
                            <nuxt-link hreflang="en-ca" class="text-color tertiary footer-menu-item" to="/contact">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Contact us
                                </h6>
                            </nuxt-link>
                        </li>
                        <li class="m-b-16">
                            <nuxt-link hreflang="en-ca" to="/terms" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Terms
                                </h6>
                            </nuxt-link>
                        </li>
                        <li class="m-b-16">
                            <nuxt-link hreflang="en-ca" to="/privacy" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Privacy
                                </h6>
                            </nuxt-link>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-6 w-50 col-md-3 col-lg-2 menus">
                    <ul class="list-unstyled">
                        <li class="m-b-20 font f-s-16 f-w-700 l-t-s-3 l-h-20">
                            Platform
                        </li>
                        <li class="m-b-16">
                            <nuxt-link hreflang="en-ca" class="text-color tertiary footer-menu-item" to="/consumer-insights-company">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Consumer Insights
                                </h6>
                            </nuxt-link>
                        </li>
                        <li class="m-b-16">
                            <nuxt-link hreflang="en-ca" class="text-color tertiary footer-menu-item" to="/product-sampling-program">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Shopper Marketing
                                </h6>
                            </nuxt-link>
                        </li>
                        <li class="m-b-16">
                            <span class="text-color tertiary footer-menu-item why" @click="handleScroll">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Why Peekage?
                                </h6>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-6 w-50 col-md-3 col-lg-2 menus">
                    <ul class="list-unstyled">
                        <li class="m-b-20 font f-w-700 l-t-s-3 l-h-20 f-s-16">
                            Resources
                        </li>
                        <li class="m-b-16 text sm font f-w-400">
                            <a hreflang="en-ca" href="https://peekage.com/blog" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">Blog</h6>
                            </a>
                        </li>
                        <li class="m-b-16 text sm font f-w-400">
                            <nuxt-link hreflang="en-ca" to="/faq" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    FAQ
                                </h6>
                            </nuxt-link>
                        </li>
                        <li class="m-b-16 text sm font f-w-400">
                            <nuxt-link hreflang="en-ca" to="/natural-shoppers-community" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Our Community
                                </h6>
                            </nuxt-link>
                        </li>
                        <!-- <li class="m-b-16 text sm font f-w-400">
                            <nuxt-link hreflang="en-ca" class="text-color tertiary footer-menu-item" to="/case-studies">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Case Studies
                                </h6>
                            </nuxt-link>
                        </li> -->
                    </ul>
                </div>
                <div class="col-sm-6 w-50 col-md-3 col-lg-2 only-device menus">
                    <ul class="list-unstyled">
                        <li class="m-b-20 font f-w-700 l-t-s-3 f-s-16 l-h-20">
                            Follow Us
                        </li>
                        <li class="m-b-16 text sm font f-w-400">
                            <a hreflang="en-ca" href="https://twitter.com/peekageapp" rel="noopener" target="_blank" class="text-color tertiary footer-menu-item"><h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">Twitter</h6></a>
                        </li>
                        <li class="m-b-16 text sm font f-w-400">
                            <a hreflang="en-ca" href="https://www.facebook.com/peekageApp/" target="_blank" rel="noopener" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Facebook
                                </h6>
                            </a>
                        </li>
                        <li class="m-b-16 text sm font f-w-400">
                            <a hreflang="en-ca" href="https://www.instagram.com/peekageapp/" target="_blank" rel="noopener" class="text-color tertiary footer-menu-item">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Instagram
                                </h6>
                            </a>
                        </li>
                        <li class="m-b-16 text sm font f-w-400">
                            <a hreflang="en-ca" href="https://www.linkedin.com/company/peekage" target="_blank" rel="noopener" class="text-color tertiary footer-menu-item" to="/sitemap">
                                <h6 class="m-0  font f-w-500 f-s-14 l-h-16 l-t-s-3">
                                    Linkedin
                                </h6>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-md-12 only-device m-t-lg-36 m-b-60 m-b-large-20 m-b-small-10 p-t-20">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="font f-s-12 l-h-16 text-color tertiary m-b-0">
                            © 2024 Ecounter Solutions Inc.
                        </p>
                        <img :src="$image('arts/techstars-logo.svg')"
                             alt="Techstars Logo"
                             width="68"
                             height="11"
                        >
                    </div>
                </div>
            </div>
        </container>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        async handleScroll() {
            const path = this.$route.path;
            if (path !== '/') {
                this.$router.push('/#WhyPeekage');
            }
            const el = document.getElementById('WhyPeekage');
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/theme/_vars.scss";

.why {
    cursor: pointer;
    :hover{
        text-decoration: underline;
    }
}
.footer-only-social {
    .menus {
        display: none;
    }
}
.footer {
    border-top: 1px solid $colorBorderStateDisabled;
    .list-unstyled {
        li {
            a {
                text-decoration: unset;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}
</style>

<template>
    <div>
        <arceus-header
            @backdrop-handler="backdropHandler"
        />
        <nuxt />
        <arceus-footer />
        <div class="backdrop only-device" :class="{ active: backdrop }" @click="backdropHandler" />
    </div>
</template>

<script>
import { hydrateWhenVisible } from 'vue-lazy-hydration';
import ArceusHeader from '~/components/layout/header/index';
import ArceusFooter from '~/components/layout/footer/index';

export default {
    components: {
        ArceusHeader,
        ArceusFooter: hydrateWhenVisible(ArceusFooter),
    },
    data() {
        return {
            backdrop: false,
        };
    },
    head() {
        return {
            htmlAttrs: {
                class: [
                    this.$store.state.device.apple && 'apple-device',
                ].filter(Boolean).join(' '),
            },
            bodyAttrs: {
                class: [
                    this.$store.state.device.android && 'android-device',
                    this.$store.state.device.apple && 'apple-device',
                    this.$store.state.device.tablet && 'tablet-device',
                    this.$store.state.device.mobile && 'mobile',
                    'theme',
                ]
                    .filter(Boolean)
                    .join(' '),
            },
        };
    },
    methods: {
        backdropHandler() {
            if (this.backdrop) {
                this.backdrop = false;
                return;
            }

            this.backdrop = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .backdrop {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
        z-index: 999;
        &.active {
            display: block;
        }
    }
</style>

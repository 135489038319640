<i18n>
    {
        "en": {
            "title": "Access Denied.",
            "description": "We are sorry, You don’t have permission to this page or resource for some reason.",
            "back": "Back to homepage"
        }
    }
</i18n>

<template>
    <div class="error-card">
        <div class="error-image">
            <img :src="$image('arts/403.png')">
        </div>
        <p class="error-message font f-s-24 f-w-800 l-h-32 mb-16">
            {{ $t('title') }}
        </p>
        <p class="font f-w-400 f-s-16 l-h-24 color secondary mb-16">
            {{ $t('description') }}
        </p>

        <nuxt-link to="/" class="btn md primary font f-w-700">
            {{ $t('back') }}
        </nuxt-link>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

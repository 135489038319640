import {
    SET_LANG,
} from '~/scripts/utils/constants/mutations/index';

export const state = () => ({
    device: {},
    locales: ['en', 'es', 'ro'],
    locale: 'en',
});

export const mutations = {
    setDevice(state, device) {
        state.device = Object.assign(state.device, device);
    },
    [SET_LANG] (state, locale) {
        if (state.locales.indexOf(locale) !== -1) {
            state.locale = locale;
        }
    },
};
export const actions = {};

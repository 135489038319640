<template>
    <div id="solutions-menu" @click.stop>
        <div class="w-100 max-width-desktop" style="min-width: 356px;">
            <div v-for="(menu, i) in subMenus" :key="i" class="menu-container">
                <div @click="setActiveMenu" :id="menu.identifier" class="p-x-10 p-y-8 w-100 item">
                    <div class="d-flex align-items-center justify-content-between w-100 item-inner">
                        <span class="font nav-text text-color primary m-r-4">{{ menu.name }}</span>
                        <span :class="['icon', 'only-device', { 'chevron-down': !menu.isActive, 'chevron-up': menu.isActive }]" />
                        <span class="icon only-desktop chevron-right" />
                    </div>
                </div>

                <div class="use-case-container">
                    <span class="sub-title font text-color tertiary">{{ menu.subtitle }}</span>
                    <div class="d-flex flex-column m-t-12" style="gap: 20px;">
                        <span class="sub-item p-y-4 font" v-for="(subItem, j) in menu.useCases" :key="j">
                            {{ subItem.case }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <Divider class="divider-vertical header-divider" />
        <div class="justify-content-between w-100 use-case-container-desktop">
            <div class="d-flex flex-column" style="gap: 32px;" v-if="activeMenu">
                <span class="sub-title font text-color tertiary">{{ activeMenu.subtitle || '' }}</span>
                <div class="d-flex row" style="gap: 20px;flex-wrap: wrap;">
                    <span class="sub-item font col-5" v-for="(subItem, i) in activeMenu.useCases" :key="i">
                        {{ subItem.case }}
                    </span>
                </div>
            </div>
            <div class="p-x-20 p-y-12 d-flex align-items-center exp m-x-20 m-t-18">
                <span class="font f-s-14 l-h-20 f-w-400 text-color tertiary">Discover how Peekage can help you exceed your business goals</span>
                <tuber is="nuxt-link" to="/contact?talk-to-an-expert=true" class="btn secondary p-x-10 f-w-700 l-t-s-3 l-h-20 p-y-8" text-class="font f-s-16 l-h-20 f-w-700 l-t-s-3">
                    Talk to an expert
                </tuber>
            </div>
        </div>

        <div class="p-x-20 p-y-12 m-x-20 d-flex flex-column only-device exp">
            <span class="font f-s-14 l-h-20 f-w-400 text-color tertiary">Discover how Peekage can help you exceed your business goals</span>
            <tuber is="nuxt-link" to="/contact?talk-to-an-expert=true" class="btn secondary p-x-60 f-w-700 l-t-s-3 l-h-20 p-y-10" text-class="font f-s-16 l-h-20 f-w-700 l-t-s-3">
                Talk to an expert
            </tuber>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavStyle2',
    props: {
        subMenus: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            activeMenuId: null,
            activeMenu: null,
        };
    },
    mounted() {
        if (window.innerWidth > 992) {
            const firstItem = this.$el.querySelector('.item');
            if (firstItem) {
                firstItem.classList.add('active-menu');
                this.activeMenuId = this.$el.querySelector('.active-menu').id;
            }
        }
        this.activeMenu = this.subMenus.find((menu) => {
            return menu.identifier === this.activeMenuId;
        });
    },
    methods: {
        setActiveMenu(event) {
            const clickedMenu = event.currentTarget;
            const isActive = clickedMenu.classList.contains('active-menu');
            this.$el.querySelectorAll('.item').forEach((item) => {
                item.classList.remove('active-menu');
                item.querySelector('.icon').classList.remove('chevron-up');
                item.querySelector('.icon').classList.add('chevron-down');
            });

            // Toggle the active class on the clicked item
            if (!isActive) {
                clickedMenu.classList.add('active-menu');
                clickedMenu.querySelector('.icon').classList.remove('chevron-down');
                clickedMenu.querySelector('.icon').classList.add('chevron-up');
            }

            if (window.innerWidth < 992) {
                this.$el.querySelectorAll('.menu-container').forEach((container) => {
                    if (container.querySelector('.active-menu')) {
                        container.classList.add('no-after');
                    } else {
                        container.classList.remove('no-after');
                    }
                });
            }

            this.activeMenu = this.subMenus.find((menu) => {
                return menu.identifier === clickedMenu.id;
            });
        },
    },
};
</script>

<style lang="scss" scoped>

.no-after{
    ::after{
        content: none !important;
    }
}
.use-case-container-desktop{
    display: none;
}

.header-divider {
        width: 100%;
        height: 2px;
        background-color: #F4F4F4;
        @media screen and (min-width: 992px) {
            height: unset;
            width: 2px;
            margin: 0 0 0 20px;
        }
    }

.menu-container {
    width: 100%;
    padding: 8px 0 8px 0;
    position: relative;
    // border-bottom: 2px solid #F4F4F4;
    ::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 89%;
        height: 2px;
        background-color: #F4F4F4;
    }
        &:nth-child(3) {
            border-bottom: none;
            ::after {
                content: none;
            }
        }
        .item {

        .item-inner {
            padding: 12px 12px;
            gap: 4px;
            cursor: pointer;
        }
    }
    .use-case-container{
        transition: display 0.3s ease-in-out;
        padding: 8px 20px 8px 32px;
        display: none;
    }
    .sub-title{
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: -0.3px;
    }
    .sub-item{
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: -0.3px;
    }
    .active-menu{
        border-radius: 4px;
        position: relative;
        .item-inner {
            background-color: #FFF1F3;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            background-color: #FF647F; // Change this to your desired color
        }
    }

    .active-menu ~ .use-case-container{
        transition: display 0.3s ease-in-out;
        color: #000;
        display: block;
    }

}
.exp{
    border: 1px solid #F4F4F4;
    border-radius: 12px;
    gap: 16px;
}

// .sub-item.active-menu {
//     background-color: #f0f0f0; // Example style for the active menu item
// }
@media screen and (min-width: 992px) {
    #solutions-menu{
        display: flex;
        width: 100%;
        max-height: 478px;
        min-height: 446px;
        .max-width-desktop{
            max-width: fit-content;
            padding: 0 0 0 15px
        }
    }
    .use-case-container-desktop {
        color: #000;
            display: flex;
            flex-direction: column;
            padding: 18px 20px 0 20px;
            .case {
                display: flex;
            }
        }
    .menu-container{
        display: flex;
        padding: 8px 0 8px 0;
        &:nth-child(1), &:nth-child(3) {
            ::after {
                content: none;
            }
        }
        .active-menu{
            border-radius: 4px;
            position: relative;
            .item-inner {
                border-radius: 12px;
                .chevron-right{
                    background-color: #ED1138;
                }
                .nav-text{
                    color: #ED1138;
                }
            }

            &:before {
                display: none;
            }
        }
    }
    .use-case-container{
        display: none !important;
    }

}
</style>

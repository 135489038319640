<template>
  <loading :no-text="noText" :position="position" :styles="{
    backgroundColor: '#FFFF',
    transition: 'all 1s',
    opacity: loading ? 1 : 0,
    zIndex: loading ? 100 : -1,
    left: 0,
    top: 0,
    height,
    position
  }" class="bg white d-flex" :class="classList" />
</template>

<script>
import Loading from './general.vue';

export default {
  components: {
    Loading,
  },
  props: {
    noText: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'absolute'
    },
    height: {
      type: String,
      default: '100%'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    classList: {
      type: String,
      default: 'justify-content-center align-items-center'
    }
  },
};
</script>

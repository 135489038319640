<template>
    <div class="horizontal-scroll p-x-small-28">
        <div class="horizontal-scroll-container" :class="containerClass">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    containerClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
    .horizontal-scroll {
        overflow: auto;
        white-space: nowrap;
        &-container {
            display: flex;
            flex-wrap: unset;
            margin-left: -10px;
            margin-right: -10px;
        }

        @media only screen and (min-width: 992px) {
            /* For desktop: */
            overflow: unset;
            white-space: inherit;
            &-container {
                flex-wrap: wrap !important;
                margin-left: -18px;
                margin-right: -18px;
            }
        }

    }
</style>

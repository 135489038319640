<template>
    <div class="p-sidebar-container">
        <div class="p-sidebar-content">
            <a><img :src="logo" /></a>
            <div class="position-relative">
              <LoadingBlocker
                  :loading="loading"
              />
              <ul class="list-unstyled menu-container">
                  <SidebarMenuItem
                      v-for="(menu, i) in menus"
                      :key="i"
                      :menu="menu"
                      @clicked="setActiveMenu"
                      :activeMenu="activeMenu"
                  />
              </ul>
            </div>
        </div>
        <div v-if="roles === 'Marketer' && totalCredit > 0" class="d-flex flex-column align-items-start" style="position: absolute; bottom: 93px; left:25px;">
          <LoadingBlocker
                    :loading="loading"
                />
          <div>
            <span class="font f-s-14 f-w-700 l-h-16 l-t-s-3"> {{availableCredit}} credits</span> <span class="font f-s-12 f-w-500 l-h-16 l-t-s-3">available</span>
          </div>
          <div class="p-b-8 p-t-8" style="width: 205px;">
              <b-progress height="8px" :max="totalCredit" :value="availableCredit" class="w-100"></b-progress>
          </div>
            <div>
              <span class="font f-s-12 f-w-700 l-h-16 l-t-s-3">{{usedCredit}}</span> / <span class="font f-s-12 f-w-500 l-h-16 l-t-s-3">{{totalCredit}} used</span>
            </div>
        </div>
        <div class="profile-container">
            <div class="profile-content position-relative">
                <LoadingBlocker
                    :loading="loading"
                />
                <div>
                    <strong class="d-block font f-s-14 f-w-700 l-h-16 m-b-2" :title="fullName">{{ truncate(fullName, { length: 20 }) }}</strong>
                    <span class="d-block font f-s-12 f-w-500 l-h-16">{{ roles }}</span>
                </div>

                <Fab
                    class="fab-tertiary p-0"
                    size="fab-sm"
                    :loading="signoutLoading"
                    @click="signout"
                    widthLoading="20px"
                    heightLoading="20px"
                >
                    <span class="icon signout size tiny"></span>
                </Fab>
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import SidebarMenuItem from '../components/Sidebar/sidebar-men-item.vue';
import Fab from '../components/Button/fab.vue';
import LoadingBlocker from '../components/Loading/block-ui.vue';

export default {
    components: {
      SidebarMenuItem,
      LoadingBlocker,
      Fab,
    },
    props: {
      logo: {
        type: String,
        required: true,
      },
      menus: {
        type: Array,
        required: true,
        default: () => ([]),
      },
      activeMenu: {
        type: Object,
        default: () => ({}),
      },
      profile: {
        type: Object,
        default: () => ({}),
      },
      signout: {
        type: Function,
        default: () => {

        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
  },
  computed: {
    creditInfo() {
      return get(this, 'profile.company.companyCreditInfo');
    },
    availableCredit() {
      return get(this, 'profile.company.companyCreditInfo.availableCredit', 0);
    },
    usedCredit() {
      return get(this, 'profile.company.companyCreditInfo.usedCredit', 0);
    },
    totalCredit() {
      return get(this, 'profile.company.companyCreditInfo.totalCredit', 0);
    },
    fullName() {
      return get(this, 'profile.email', '');
    },
    roles() {
      return get(this, 'profile.roles', '');
    },
  },
  data() {
    return {
      signoutLoading: false,
    };
  },
  methods: {
    truncate,
    setActiveMenu(menu) {
      this.$emit('select', menu);
      this.$store.commit('pageState/set', {});
    },
  },
};
</script>

<style scoped>
/* level settings 👇 */

.slider {
  /* slider */
  --slider-width: 100%;
  --slider-height: 8px;
  --slider-bg: grey;
  --slider-border-radius: 999px;
  /* level */
  --level-color: #1B63F5;
  /* --level-transition-duration: .1s; */
  /* icon */
  --icon-margin: 15px;
  --icon-color: var(--slider-bg);
  --icon-size: 25px;
}

.slider {
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider .volume {
  display: inline-block;
  vertical-align: top;
  margin-right: var(--icon-margin);
  color: var(--icon-color);
  width: var(--icon-size);
  height: 30px;
}

.slider .level {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--slider-width);
  height: var(--slider-height);
  background: var(--slider-bg);
  overflow: hidden;
  border-radius: 100px;
  /* -webkit-transition: height var(--level-transition-duration); */
  /* -o-transition: height var(--level-transition-duration); */
  /* transition: height var(--level-transition-duration); */
  cursor: inherit;
}

.slider .level::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0;
  height: 0;
  -webkit-box-shadow: -200px 0 0 200px var(--level-color);
  box-shadow: -200px 0 0 200px var(--level-color);
}
</style>
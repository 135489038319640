<template>
    <a class="post-item d-sm-inline-block scrollmenu-item m-b-small-20 text-color primary"
        target="_blank"
        :href="post.link"
        hreflang="en-ca"
        data-aos="fade-in"
    >
        <img class="post-image m-b-16 w-100 lazyload" :data-src="post.image">
        <Heading
            tag="h5"
            size="x-sm"
            color="primary"
            style="letter-spacing: -0.75px;"
            class="post-title ellipsis m-b-8 f-w-600"
        >
            {{ post.title.rendered | encode }}
        </Heading>
        <Texture
            v-html="post.excerpt.rendered"
            class="ellipsis ellipsis-3 post-content m-b-10 f-w-400"
            color="secondary"
            size="sm"
        />
    </a>
</template>

<script>
import Heading from '../Heading/heading.vue';
import Texture from '../Text/text.vue';

export default {
  components: {
    Heading,
    Texture,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    encode(str) {
      if (process.client) {
        const el = document.createElement('div');
        el.innerHTML = str;

        return el.innerText;
      }

      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
    .post-item {
        text-decoration: unset;
        max-width: 262px;
        min-width: 262px;
        margin: 0 10px;
        * {
            white-space: normal;
        }
        .post-title {
            height: 56px;
        }
        .post-content {
            height: 60px;
        }
        &:hover {
            opacity: 0.7 !important;
            .post-title {
                color: #009B8E !important
            }
        }
        .post-image {
            height: 204px;
            border-radius: 5px;
            @media (max-width: 768px) {
                height: 133px;
            }
        }

        @media only screen and (min-width: 992px) {
            /* For desktop: */
            max-width: 364px;
            margin: 0 18px;
            .post-content {
                height: 80px;
            }
        }
    }
</style>

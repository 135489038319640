<template>
    <component :is="tag" :class="classList">
        <slot></slot>
    </component>
</template>

<script>
import colors from '../colors.json';

export default {
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    size: {
      type: String,
      default: 'lg',
    },
    color: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      sizes: {
        lg: 'lg',
        md: 'md',
        sm: 'sm',
        'x-sm': 'x-sm',
      },
      colors: { ...colors },
      classes: [],
    };
  },
  computed: {
    classList() {
      this.classes.push('font text-color');
      this.classes.push(this.type);
      this.classes.push(this.sizes[this.size]);
      this.classes.push(this.colors[this.color]);

      return this.classes.join(' ');
    },
  },
};
</script>

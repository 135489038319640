<template>
    <HorizontalScroll>
        <post
            :key="i"
            v-for="(post, i) in posts"
            :post="post"
        />
    </HorizontalScroll>
</template>

<script>
import Post from './post.vue';
import HorizontalScroll from '../Utils/horizontal-scroll.vue';

export default {
  components: {
    Post,
    HorizontalScroll,
  },
  props: {
    posts: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" scoped>

</style>

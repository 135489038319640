var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-container",style:({
    width: ("" + _vm.width),
    minWidth: ("" + _vm.width),
    height: ("" + _vm.height),
    minHeight: ("" + _vm.height),
    backgroundImage: ("url(" + _vm.source + ")"),
    backgroundSize: _vm.size
})})}
var staticRenderFns = []

export { render, staticRenderFns }
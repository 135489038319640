<template>
    <div @click="$emit('handleCollapse')" id="resources-menu">
        <div class="container-wrapper">
            <div class="m-b-12 resource-desktop">
                <span class="font f-s-16 f-w-500 l-h-20 l-t-s-3 text-color tertiary m-b-12">Resources</span>
            </div>
            <div v-for="(menu, i) in subMenus" :key="i" class="menu-container">
                <!-- <nuxt-link :to="menu.uri" target="_blank"> -->
                <div :id="menu.identifier" class="w-100 item" @click="handleNavigate(menu)">
                    <div
                        class="d-flex align-items-center justify-content-start w-100 item-inner"
                        @mouseover="handleMouseOver"
                        @mouseleave="handleMouseLeave"
                    >
                        <span class="font nav-text text-color primary m-r-4">{{ menu.name }}</span>
                        <span class="icon size x-sm arrow-right d-none" style="max-height: 40px;" />
                    </div>
                </div>
                <!-- </nuxt-link> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavStyle3',
    props: {
        subMenus: {
            type: Array,
            default: () => ([]),
        },
    },
    methods: {
        handleNavigate(menu) {
            if (menu.uri.startsWith('/')) {
                this.$router.push(menu.uri);
                return;
            }
            window.location.href = menu.uri;
        },
        handleMouseOver(event) {
            const chevron = event.currentTarget.querySelector('.arrow-right');
            if (chevron) {
                chevron.classList.remove('d-none');
            }
        },
        handleMouseLeave(event) {
            const chevron = event.currentTarget.querySelector('.arrow-right');
            if (chevron) {
                chevron.classList.add('d-none');
            }
        },
    },
};
</script>

<style lang="scss" scoped>

#solution-menu {
    // padding: 0 10px;
}

.container-wrapper{
    min-width: 356px;
    width: 100%;
    padding: 0 20px;
    .resource-desktop{
            display: none;
        }
    .menu-container {
        &:last-child {
        border-bottom: none;
        }
    width: 100%;
    border-bottom: 2px solid #F4F4F4;
    padding: 20px 0;
        .item {

                .item-inner {
                    gap: 4px;
                    min-height: 30px;
                }
    }
}
}

@media screen and (min-width: 992px) {
    #resources-menu{
        display: flex;
        max-height: 478px
    }
    .container-wrapper{
        width: 260px;
        min-width: unset;
        padding: 10px 32px;

        .resource-desktop{
            display: block;
        }
        .menu-container{
            display: flex;
            border-bottom: unset;
            padding: 10px 0;
            max-width: 236px;
                .item-inner {
                    border-radius: 12px;
                    &:hover{
                                text-decoration: underline !important;
                    }
            }
        }
    }
}
</style>

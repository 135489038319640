<template>
    <div class="pagination">
        <div class="pagination-navigation">
            <button :disabled="disabledPrevButton" @click="handler(--pagination.current)">Prev</button>
            <template v-for="(page, i) in totalPage">
              <Page @handler="handler" :key="i" :page="page" :current="pagination.current"/>
            </template>
            <button :disabled="disabledNextButton" @click="handler(++pagination.current)">Next</button>
        </div>
    </div>
</template>

<script>
import Page from './page-number.vue';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        totalCount: 0,
        current: 1,
        next: 2,
        prev: 0,
        perpage: 10,
      },
    };
  },
  computed: {
    totalPage() {
      return Array.from({ length: (this.pagination.totalCount / this.pagination.perpage) }, (_, i) => i + 1);
    },
    disabledNextButton() {
      console.log(this.pagination.current, (this.totalPage.length - 1));
      return this.pagination.current > (this.totalPage.length - 1);
    },
    disabledPrevButton() {
      return this.pagination.current <= 1;
    },
  },
  mounted() {
    this.pagination = { ...this.pagination, ...this.value };
  },
  components: {
    Page,
  },
  watch: {
    pagination: {
      deep: true,
      handler(paginate) {
        this.$emit('input', paginate);
      },
    },
  },
  methods: {
    handler(current) {
      this.pagination = Object.assign(this.pagination, { current });
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  &-navigation {
    &__counter {
      width: 33px;
      height: 34px;
      display: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all .1s;
      margin-left: 4px;
      margin-right: 4px;
      &.active , &:hover {
        // width: 31px;
        border-radius: 8px;
        box-shadow: inset 0 0 200px 100px rgba(20, 20, 20, 0.12);
      }

      &.show {
        display: inline-flex !important
      }
    }
  }
}
</style>

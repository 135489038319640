<template>
    <div class="header-container" @click="$emit('handleVisible')">
        <div class="only-device d-flex align-items-center p-x-20" style="gap: 12px;">
            <tuber
                @click="$emit('handleVisible')"
                class="btn secondary md font f-s-16 l-h-20 f-w-700 d-inline-flex justify-content-start"
            >
                <span class="icon arrow-left size sm m-r-4" />
                Back
            </tuber>
            <span class="font f-s-16 l-h-20 f-w-500 l-t-s-3 text-color tertiary">{{ menuName }}</span>
        </div>

        <component
            :is="menuVersion"
            :sub-menus="subMenus"
            @handleCollapse="$emit('handleCollapse')"
        />
    </div>
</template>

<script>
import Style1 from '~/components/layout/header/style-1';
import Style2 from '~/components/layout/header/style-2';
import Style3 from '~/components/layout/header/style-3';

export default {
    name: 'NavItem',
    components: {
        Style1,
        Style2,
        Style3,
    },
    props: {
        subMenus: {
            type: Array,
            default: () => ([]),
        },
        menuVersion: {
            type: String,
            default: 'style1',
        },
        menuName: {
            type: String,
            default: '',
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateTargetWidth);
        this.updateTargetWidth();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateTargetWidth);
    },
    methods: {
        updateTargetWidth() {
            const sourceDiv = document.getElementById('navbar');
            const targetDivs = document.querySelectorAll('#nav-item-style-1');
            if (targetDivs.length > 0) {
                const targetDiv = targetDivs[0];
                const sourceDivWidth = sourceDiv.offsetWidth;
                targetDiv.style.width = sourceDivWidth + 'px';
            }
            if (targetDivs.length > 1) {
                const targetDiv = targetDivs[1];
                const sourceDivWidth = sourceDiv.offsetWidth;
                targetDiv.style.width = sourceDivWidth + 'px';
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.header-container {
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    border-radius: var(--Sizing-Space-500, 20px);
    background: #FFF;
    overflow-y: scroll;
    max-height: 85vh;
    text-overflow: ellipsis;
    padding: 20px 0 !important;
    // overflow: hidden;
    @media screen and (min-width: 992px) {
        flex-direction: row;
        overflow-y: unset;
    }

    .nav-img {
        width: 80px;
        height: 80px;
        @media screen and (min-width: 992px) {
            width: 120px;
            height: 120px;
        }
    }
}

</style>

<template>
    <span @click="handler" class="font f-w-bold f-s-14 l-h-18 pagination-navigation__counter" :class="{
        active,
        show
    }">{{ page }}</span>
</template>

<script>
export default {
  props: {
    page: {
      type: [Number],
      default: 1,
    },
    current: {
      type: [Number],
      default: 1,
    },
  },
  computed: {
    active() {
      return this.page === this.current;
    },
    show() {
      return this.check();
    },
  },
  methods: {
    handler() {
      this.$emit('handler', this.page);
    },
    check() {
      if (((this.current - 2) >= this.page || (this.current + 2) < this.page)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<template>
  <div class="input-container" :class="classes">
    <div class="d-flex justify-content-between align-items-center">
      <span v-if="label" class="d-flex align-items-center m-b-8">
        <label class="font f-w-500 f-s-16 l-h-20 m-0 l-t-s-3">{{ label }}</label>

        <template v-if="hasTooltip">
          <span :id="tooltipId" style="cursor: help;" class="icon size tiny alert-info-fill color primary"
            :style="tooltipCustomStyle"></span>
          <b-tooltip triggers="hover" :target="tooltipId" custom-class="input-tooltip-class">
            {{ tooltipText }}
          </b-tooltip>
        </template>

      </span>
      <CharCounter default-classes="f-s-16 l-h-20 f-w-500 m-r-12" v-if="charCounter" ref="charCounter" :max="max"
        :count="count" />
    </div>
    <label class="input-body m-b-0" :id="bodyId" :for="inputFieldId" :style="bodyStyles" :class="bodyClass">
      <slot name="container"></slot>
    </label>
    <div class="input-assistive" :class="{ 'd-none': !assistive, 'input-assistive-none': noAssistive }">
      <span class="font f-s-14 l-h-20 f-w-400">{{ assistive }}</span>
    </div>
  </div>
</template>

<script>
import CharCounter from '../char-counter.vue';

export default {
  components: {
    CharCounter,
  },
  computed: {
    tooltipId() {
      return `tooltip-id-${Math.random()}`
    },
    hasTooltip() {
      return this.tooltipText.length
    },
  },
  props: {
    tooltipCustomStyle: {
      type: Object,
      default: () => ({})
    },
    tooltipText: {
      type: String,
      default: '',
    },
    inputFieldId: {
      type: String,
      default: `input-field-id-${Math.random()}`
    },
    bodyClass: {
      type: String,
      default: ''
    },
    bodyId: {
      type: String,
      default: '',
    },
    noAssistive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    max: {
      type: [String, Number],
      default: 120,
    },
    count: {
      type: [String, Number],
      default: 0,
    },
    charCounter: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: 'p-b-16',
    },
    bodyStyles: {
      type: [String, Object],
      default: '',
    },
  },
};
</script>

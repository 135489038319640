<template>
    <div>
        <div v-if="helper" class="m-b-12">
          <span class="m-b-8 font f-s-16 l-h-20 f-w-500 d-block">{{ label }}</span>
          <span v-if="!hideImageSpecs">
              <span class="m-b-8 font f-s-14 l-h-20 f-w-400">Image specs:</span>
              <span class="m-b-8 font f-s-14 l-h-20 f-w-700">{{ imageSpecs }}</span>
          </span>
        </div>

        <div class="file-uploader" :style="{
          width: !helper ? width : 'auto'
        }" :class="{ invalid }">
          <div class="file-uploader-body" :class="state" :style="{
              maxWidth: width,
              minHeight: height,
              width: width,
              height: height
            }">
              <label :for="`file-uploader-input-${id}`" class="file-uploader-body-container" @drop="dropHandler($event)" @dragover="dragOverHandler($event)" :style="{
                  backgroundImage: filePath ? `url(${filePath}) !important`: ''
              }">
                  <span class="file-uploader-body-placeholder" v-if="!image">
                      <span class="d-block icon size sm file-upload color primary"></span>
                      <span class="font f-s-14 l-h-16 f-w-500">{{ placeholder || 'Upload or drop your files here' }}</span>
                  </span>
              </label>
              <div class="file-uploader-body-toolbar-container" v-if="!disabled && image">

                  <div class="file-uploader-body-toolbar-panel justify-content-end" v-if="file.validation && file.validation.length">
                      <button @click="reset" class="btn fab fab-x-sm icon delete color onColor" ></button>
                  </div>

                  <div class="file-uploader-body-toolbar-panel justify-content-end" v-if="uploading">
                      <LoadingSvg loadingColor="#fff" />
                  </div>

                  <div class="file-uploader-body-toolbar-panel" v-if="uploaded || hasUploadError">
                      <button type="button" @click="upload" class="btn fab fab-x-sm icon reload color onColor" v-if="hasUploadError"></button>
                      <button type="button" @click="show" class="btn fab fab-x-sm icon fullscreen-open color onColor" v-if="uploaded"></button>
                      <button type="button" @click="reset" class="btn fab fab-x-sm icon delete color onColor" ></button>
                  </div>
              </div>
              <input v-if="!disabled" type="file" ref="input" :id="`file-uploader-input-${id}`" :accept="accept.join(',')" :multiple="multiple" @change="chooseFileHandler" class="file-uploader-body-input"/>
          </div>

          <div class="file-uploader-assistive" :class="{ 'd-none': !assistive, 'file-uploader-assistive-none': noAssistive }">
            <span class="font f-s-14 l-h-20 f-w-400">{{ assistive }}</span>
          </div>
        </div>

    </div>
</template>

<script>
import map from 'lodash/map';
import head from 'lodash/head';
import has from 'lodash/has';
import chunk from 'lodash/chunk';

import LoadingSvg from '../Loading/loading-svg.vue';

export default {
  components: {
    LoadingSvg,
  },
  props: {
    validate: {
      type: Boolean,
      default: true,
    },
    setErrors: {
      type: Function,
      default: () => {

      },
    },
    checkAspectRatio: {
      type: Boolean,
      default: true
    },
    accept: {
      type: Array,
      default: () => (['image/png', 'image/jpeg', 'image/gif']),
    },
    ratioHeight: {
      type: Number,
      default: 512,
    },
    ratioWidth: {
      type: Number,
      default: 512,
    },
    maxFileUpload: {
      type: Number,
      default: 1,
    },
    maxFileSize: {
      type: Number,
      default: 2,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Background Image',
    },
    imageSpecs: {
      type: String,
      default: '1080 x 1920px - jpg,png,gif - Max 2MB',
    },
    hideImageSpecs: {
      type: Boolean,
      default: false,
    },
    helper: {
      type: Boolean,
      default: true,
    },
    noAssistive: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '180px',
    },
    width: {
      type: String,
      default: '135px',
    },
    uri: {
      type: String,
      default: '',
      // required: true
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {},
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: [Boolean, String, Array],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    reduce: {
      type: Function,
      default(image) { return image; },
    },
    customeValidationMessages: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { uri, value } = this;
    return {
      state: uri || value ? 'uploaded' : '',
      file: false,
      files: [],
      image: uri || '',
    };
  },
  computed: {
    uploading() {
      return this.state === 'uploading';
    },
    hasUploadError() {
      return this.state === 'uploading-error';
    },
    uploaded() {
      return this.state === 'uploaded';
    },
    id() {
      return Math.random();
    },
    invalid() {
      return this.error.length;
    },
    filePath() {
      return this.image || '';
    },
  },
  watch: {
    files(files) {
      if (!this.multiple && files.length) {
        const file = head(files);
        this.file = file;
      }
    },
    file(file) {
      this.readImageFile(file);

      if (!file.validation || !file.validation.length) {
        this.upload();
      }

      if (file.validation && file.validation.length) {
        const { message } = file.validation[0];
        this.setErrors(message);
      }
    },
    uri(uri) {
      this.image = uri;
    },
    value(value) {
      if (has(value, 'uri')) {
        this.uri = value.uri;
        this.state = 'uploaded';
      }
    },
  },
  methods: {
    chooseFileHandler(e) {
      try {
        this.validateFiles(e.target.files);
      } catch (error) {
        console.log(error);
      }
    },
    dragOverHandler(e) {
      e.preventDefault();
    },
    dropHandler(e) {
      e.preventDefault();
      try {
        if (this.disabled) return;

        let files = [];

        if (e.dataTransfer.items) {
          files = map(e.dataTransfer.items, (file) => {
            if (file.kind === 'file') {
              return file.getAsFile();
            }
            return false;
          }).filter(Boolean);
        } else {
          files = map(e.dataTransfer.items, (file) => file);
        }

        this.validateFiles(files);
      } catch (error) {
        console.log(error);
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const image = new Image();
        reader.onload = () => {
          image.src = reader.result;

          image.onload = function (img) {
            const { height, width } = this || img.path[0];
            resolve({
              height,
              width,
            });
          };
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },
    async validateFiles(files) {
      const bundle = [...files];

      if (this.validate) {
        for(var n = 0; n < bundle.length; n++) {
          const file = bundle[n];
          bundle[n].validation = [];

          if (!this.accept.includes(file.type)) {
            bundle[n].validation.push({
              isNotValid: this.accept.includes(file.type),
              message: this.customeValidationMessages.unsupport || 'The format of the cover image is unsupported.',
            });
          }

          if(this.checkAspectRatio) {
            const dimension = await this.readFileAsync(file);

            if (dimension.height !== this.ratioHeight || dimension.width !== this.ratioWidth) {
              bundle[n].validation.push({
                isNotValid: (dimension.height !== this.ratioHeight || dimension.width !== this.ratioWidth),
                message: this.customeValidationMessages.dimension || 'The aspect ratio of the image is wrong.',
              });
            }
          }
          
          if (((file.size / 1000000) >= this.maxFileSize)) {
            bundle[n].validation.push({
              isLarge: ((file.size / 1000000) >= this.maxFileSize),
              message: this.customeValidationMessages.size || `The size of the image is larger than ${this.maxFileSize}MB.`,
            });
          }
        }
      }

      setTimeout(() => {
        const chunked = chunk(bundle, this.maxFileUpload);
        this.files = [...chunked[0]];
      }, 1000);
    },
    readImageFile(file) {
      try {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
          this.image = event.target.result;
        });
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error);
      }
    },
    upload() {
      const { file, index } = this;
      this.state = 'uploading';

      this.$emit('upload', {
        file,
        changeState: ({ error, raw, image }) => {
          if (error) {
            this.state = 'uploading-error';
          }

          if (!error) {
            this.state = 'uploaded';
            const img = image || raw;
            if (img) {
              this.$emit('input', this.reduce(img, index));
            }
          }
        },
      });

      this.$refs.input.value = '';
    },
    show() {
      this.$emit('show-image', [this.image]);
    },
    reset() {
      this.$emit('remove', this.value);
      this.$emit('input', null);
      this.$refs.input.value = '';
      this.image = null;
    },
  },
};
</script>

<template>
    <div class="container-fluid p-container" :class="size">
        <slot />
    </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'md',
    },
  },
};
</script>

<template>
  <span class="chips font f-s-14 f-w-500">
    <slot>
      {{ label }}
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [Object, String],
      default: () => ({}),
    },
    inputData: {
      type: [Object, String],
      default: "",
    },
  },
  methods: {
    clicked() {
      this.$emit("click", this.inputData);
    },
  },
};
</script>

<style lang="scss">
.chips {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  border-radius: 24px;
  margin: 0px 4px;
  padding: 7px 12px;
  &:hover {
    // border: 2px solid #CBCBCB !important;
  }
  &.active {
    background: rgba(255, 79, 102, 0.1);
    border: 1px solid #ff4f66;
    color: #ff4f66;
    &-V2 {
      border-radius: 24px;
      border: 1px solid var(--border-transparent, rgba(0, 0, 0, 0.1)) !important;
      background-color: var(--intractive-base, #1b63f5) !important;
      color: #ffff;
    }
  }
}
</style>

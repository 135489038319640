<template>
    <li class="menu-item"
        v-on:click="setActiveMenu(menu)"
    >
        <nuxt-link class="menu-link font f-s-14 l-h-16 f-w-700" :class="{active: active }" :to="menu.path">
            <span class="icon size sm m-r-12" :class="menu.icon"></span>
            {{ menu.name }}
        </nuxt-link>
    </li>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
    activeMenu: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    active() {
      return this.activeMenu.name === this.menu.name;
    },
  },
  methods: {
    setActiveMenu() {
      this.$emit('clicked', this.menu);
    },
  },
};
</script>

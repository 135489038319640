<template>
  <input-container class="search-container" :label="label" :assistive="assistive" :no-assistive="noAssistive"
    :class="{ focused, disabled, invalid, noIcon }" :classes="containerClasses">
    <template #container>
      <span v-if="!noIcon" class="icon search size sm m-r-4 m-l-12 color primary"></span>
      <v-select v-model="selected" ref="vselect" :closeOnSelect="closeOnSelect" :clearSearchOnSelect="clearSearchOnSelect"
        :searchable="true" :placeholder="placeholder" :options="arrayList" @search="search" @open="focus" @close="blur"
        :label="keyName" :loading="loading" :clearable="clearable" :filterable="filterable" :reduce="reduce" name="search"
        :no-drop="noDrop" :disabled="disabled" :getOptionKey="() => { return Math.random().toString() }">

        <template #open-indicator="{ attributes }">
          <span v-if="!noCarret" v-bind="attributes" class="icon expand-more color primary"></span>
          <span v-else></span>
        </template>

        <template #no-options="{ }">
          <div class="text-left p-x-12 p-y-14">
            <span class="font f-s-16 l-h-20 f-w-500 d-block m-b-4">No result found</span>
            <span class="font f-s-14 l-h-16 f-w-500 d-block">Try searching with different words</span>
          </div>
        </template>

        <template #list-footer>
          <slot name="list-footer"></slot>
        </template>

      </v-select>
    </template>
  </input-container>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';

import InputContainer from './input-container.vue';
// import InputComponent from './input-component.vue';

export default {
  components: {
    InputContainer,
    // InputComponent,
    vSelect,
  },
  data() {
    return {
      focused: false,
      autocomplete: '',
      arrayList: this.options || [],
    };
  },
  props: {
    noAssistive: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    options: {
      type: [Array, Object],
      default: () => ([]),
    },
    clean: {
      type: Boolean,
      default: false,
    },
    value: {},
    label: {
      type: [String, Boolean],
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search here...',
    },
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    classes: {
      type: String,
      default: '',
    },
    containerClasses: {
      type: String,
      default: 'p-b-16',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      default: '',
    },
    valueName: {
      type: String,
      default: 'value',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    noCarret: {
      type: Boolean,
      default: false,
    },
    clearSearchOnSelect: {
      type: Boolean,
      default: true,
    },
    currentSearchvalue: {
      type: String,
      default: '',
    },
    reduce: {
      type: Function,
      default(option) {
        return option[this.valueName];
      },
    },
    noDrop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(selected) {
        this.$emit('input', selected);
      },
    },
    showCleanButton() {
      return !this.disabled && this.clean && this.text;
    },
    invalid() {
      return Boolean(this.error || this.error.length);
    },
    curentValue() {
      return 'test';
    },
  },
  watch: {
    options(options) {
      this.arrayList = options;
    },
    currentSearchvalue(value) {
      this.$refs.vselect.search = value;
    },
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    empty() {
      this.text = '';
    },
    selectedOption() { },
    search(search) {
      if (this.currentSearchvalue !== search) {
        this.$emit('search', search);
      }
    },
  },
};
</script>

<style lang="scss">
.search {
  &-container {
    &.noIcon {
      .input-body {
        .v-select {
          padding-left: 12px;
        }
      }
    }

    .input-body {
      overflow: unset !important;
      display: block !important;
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .v-select {
      border-radius: 8px;
      width: 100%;
      padding-left: 52px;

      &.vs--disabled {
        .vs__selected {
          color: inherit !important;
        }

        .vs__open-indicator {
          background-color: #AFAFAF !important;
        }
      }

      .vs__dropdown-toggle {
        padding: 0px;
        border-radius: 8px;
        height: 44px;
        border: unset;
      }

      &.vs--open {
        .vs__actions {
          .vs__open-indicator {
            transform: rotate(180deg);
          }
        }
      }

      .vs__selected-options {
        justify-content: center;
        padding: 0px;
        flex-wrap: nowrap;
        overflow: hidden;

        .vs__search {
          opacity: 1 !important;
          margin: 0px;
          padding-left: 0px;

          &::placeholder {
            color: #757575;
          }
        }

        .vs__selected {
          margin: 0px;
          padding: 0px;
          position: unset !important;
          white-space: nowrap;
        }
      }

      .vs__actions {
        padding-right: 12px;
        justify-content: center;

        .vs__clear {
          width: 24px;
          height: 24px;
        }

        .vs__open-indicator {
          transition: all .0.5s;
          transform: unset;
          transition-timing-function: unset;
          position: relative;
          top: unset;
        }
      }

      .vs__dropdown-menu {
        border-radius: 8px;
        padding: 0px;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
        background: #FFFFFF;
        top: calc(100% + 4px);

        li {
          &:first-child {
            margin-top: 8px;
          }

          &:last-child {
            margin: 0 8px 8px 8px;
          }

          margin: 0 8px;
        }

        &::-webkit-scrollbar {
          width: 4px;

          // padding-right: 8px;
          &-track {
            box-shadow: unset;
            border-radius: 0;
            padding-right: 8px;
          }

          &-thumb {
            background: #AFAFAF;
            border-radius: 4px;
            padding-right: 8px;
          }
        }
      }

      .vs__dropdown-option {
        white-space: normal;
        min-height: 48px;
        padding: 14px 12px;

        &--highlight {
          background: #EEEEEE;
          color: #141414;
          padding: 14px 12px;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>

<template>
    <div class="widget">
        <nuxt />
    </div>
</template>

<script>
export default {
    head() {
        return {
            htmlAttrs: {
                class: [
                    this.$store.state.device.apple && 'apple-device',
                ].filter(Boolean).join(' '),
            },
            bodyAttrs: {
                class: [
                    this.$store.state.device.android && 'android-device',
                    this.$store.state.device.apple && 'apple-device',
                    this.$store.state.device.tablet && 'tablet-device',
                    this.$store.state.device.mobile && 'mobile',
                    'widget-body',
                    'old-theme',
                ]
                    .filter(Boolean)
                    .join(' '),
            },
        };
    },
};
</script>

<template>
    <span class="tag">
        <slot>
          <span v-if="icon" :class="icon" class="icon sm tag-icon"></span>
          <span v-if="label" class="tag-title">{{ label }}</span>
          <button @click="click" v-show="cancel" type="button" class="btn tag-cancel icon close"></button>
          <span v-if="tooltipText" :id="Math.random()" v-b-tooltip :title="tooltipText" class="icon size tiny alert-info-fill m-l-2"></span>
        </slot>
    </span>
</template>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      default: ''
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    label: {
      type: [String, Boolean],
      default: '',
    },
    cancel: {
      type: [Boolean],
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.tag {
    text-transform: inherit;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    padding: 6px 12px;
    background: linear-gradient(0deg, rgba(0, 153, 255, 0.15), rgba(0, 153, 255, 0.15));
    color: #0099FF;
    &-circle {
      width: 28px;
      height: 28px;
    }

    .icon {
      background-color: #0099FF;
    }

    &-blue-dark {
      background: linear-gradient(0deg, rgba(14, 71, 192, 0.15), rgba(14, 71, 192, 0.15));
      color: #0E47C0;
      .icon {
        background-color: #0E47C0;
      }
    }

    &-negative {
      background-color: #FFEFED;
      color: #E11900;
      .icon {
        background-color: #E11900;
      }      
    }

    &-success {
      background: #ddf6ef;
      color: #20C598;
      .icon {
        background-color: #20C598;
      }
    }

    &-success-dark {
      background: #E6F2ED;
      color: #05944F;
      .icon {
        background-color: #05944F;
      }
    }

    &-pink {
      background: linear-gradient(0deg, rgba(223, 78, 184, 0.15), rgba(223, 78, 184, 0.15));
      color: #DF4EB8;
      .icon {
        background-color: #DF4EB8;
      }
    }

    &-light-warning {
      background: #FFFAF0;
      color: #BC8B2C;
      .icon {
        background-color: #BC8B2C;
      }
    }

    &-warning {
      background: rgba(247, 136, 52, 0.15);
      color: #F78834;
      .icon {
        background-color: #F78834;
      }
    }

    &-purple {
      background: linear-gradient(0deg, rgba(152, 53, 255, 0.15), rgba(152, 53, 255, 0.15)), #FFFFFF;
      color: #9835FF;
      .icon {
        background-color: #9835FF;
      }
    }

    &-danger {
      background: linear-gradient(0deg, rgba(255, 60, 85, 0.15), rgba(255, 60, 85, 0.15));
      color: #FF3C55;
      .icon {
        background-color: #FF3C55;
      }
    }
    &-light-danger {
      background: rgba($color: #FF6778, $alpha: 0.15);
      color: #FF6778;
      .icon {
        background-color:  #FF6778;
      }
    }

    &-disabled {
      background: rgba($color: #AFAFAF, $alpha: 0.15);
      color: #AFAFAF;
      .icon {
        background-color:  #AFAFAF;
      }
    }

    &-cancel {
      margin-left: 9px;
      width: 12px;
      height: 12px;
      padding: 0px;
    }
    &-icon {
      margin-right: 9px;
      width: 12px;
      height: 12px;
    }

    &-md {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      height: 32px;
    }

    &-sm {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      padding: 4px 8px;
      height: 24px;
    }

    &-x-sm {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding: 2px 6px;
      height: 20px;
    }

    &-xx-sm {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding: 2px 6px;
      letter-spacing: -0.3px;
      height: 16px;
    }    

}
</style>

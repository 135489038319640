class Logger {
    constructor (options) {
        this.options = Object.assign({
            prefix: '',
            enabled: true,
        }, options);
    }

    log (level, arg0, ...args) {
        if (!this.options.enabled) {
            return;
        }

        console.log(`${this.options.prefix}`, arg0, ...args); // eslint-disable-line no-console
    }

    table (items = []) {
        if (!this.options.enabled) {
            return;
        }

        if (items.length > 0) {
            console.table(JSON.parse(JSON.stringify(items))); // eslint-disable-line no-console
        } else {
            console.table([{ [this.options.prefix]: 'Empty' }]); // eslint-disable-line no-console
        }
    }

    debug () {
        this.log('debug', ...arguments);
    }

    group (label, fn) {
        if (!this.options.enabled) {
            return Promise.resolve(fn());
        }

        console.group(label); // eslint-disable-line no-console
        return Promise.resolve(fn()).then(() => {
            console.groupEnd(); // eslint-disable-line no-console
        });
    }

    scope (scope) {
        return new Logger(Object.assign(this.options, {
            prefix: scope,
        }));
    }
}

export default function loggerPlugin (ctx, inject) {
    let enabled = process.env.NODE_ENV === 'development';

    if (!enabled && typeof localStorage !== 'undefined') {
        enabled = Boolean(localStorage.getItem('nuxtLogger'));
    }

    const logger = new Logger({
        enabled,
    });

    ctx.logger = logger;

    inject('logger', logger);
}

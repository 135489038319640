<template>
    <component :is="tag" type="button" @click="clicked" :disabled="disabled" :class="`fab ${ size }`" >
        <slot v-if="!loading"></slot>

        <LoadingSvg
          v-if="loading"
          :loadingColor="loadingColor"
          :width="widthLoading"
          :height="heightLoading"
        />
    </component>
</template>

<script>
import LoadingSvg from '../Loading/loading-svg.vue';

export default {
  components: {
    LoadingSvg,
  },
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'fab-md',
    },
    widthLoading: {
      type: String,
      default: '30px',
    },
    heightLoading: {
      type: String,
      default: '30px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingColor: {
      type: String,
      default: '#000',
    },
  },
  methods: {
    clicked(e) {
      this.$emit('click', e);
    },
  },
};
</script>

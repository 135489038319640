import Vue from 'vue';
import debounce from 'lodash/debounce';

Vue.mixin({
    created () {
        this._intervals = [];
        this._timeouts = [];
        this._windowListeners = [];
    },
    beforeDestroy () {
        (this._intervals || []).forEach(h => clearInterval(h));
        (this._timeouts || []).forEach(h => clearTimeout(h));
        (this._windowListeners || []).forEach(({ event, fn }) => window.removeEventListener(event, fn));
    },
    methods: {
        $setInterval (fn, time) {
            if (process.server) {
                fn();
            } else {
                if (!this._intervals) {
                    this._intervals = [];
                }
                const i = setInterval(fn, time);
                this._intervals.push(i);
                return i;
            }
        },
        $setTimeout (fn, time) {
            if (process.server) {
                fn();
            } else {
                if (!this._timeouts) {
                    this._timeouts = [];
                }
                const i = setTimeout(fn, time);
                this._timeouts.push(i);
                return i;
            }
        },
        $addWindowListener (event, fn, debounceTimout = 0) {
            if (process.server) return;

            if (debounceTimout) fn = debounce(fn, debounceTimout);

            window.addEventListener(event, fn);
            this._windowListeners.push({ event, fn });
        },
    },
});

const { warn } = console;

window.dataLayer = window.dataLayer || [];

export default async function ({ app }, inject) {
    const logger = app.$logger.scope('[GTM]');

    let lastPageViewPath = '';

    function sendPageView (route = app.router.currentRoute, head = { title: '' }) {
        // head.title should contain the title of the current route
        // if it doesn't, check if a page title exists in the store (_route.vue)
        // if it doesn't, send the title of the entire app instead (app.head.title)
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: route.fullPath,
                title: head.title || app.head.title,
            },
        });
        logger.debug('👀 page view ' + route.fullPath);
    }

    function sendGTMEvent (payload) {
        if (payload) {
            if (!payload.eventValue) {
                // add a default value of zero whenever an event has no value
                // this prevents data from becoming dirty in Google Analytics
                payload.eventValue = 0;
            }
            try {
                window.dataLayer.push(payload);
            } catch (error) {
                warn('[GTM]', error);
            }
        }
    }

    // Every time the route changes (fired on initialization too)
    app.router.afterEach((to, from) => {
        if (lastPageViewPath === to.fullPath || !from.name) {
            return;
        }

        const { head } = to.matched[0].components.default.options;

        const shouldSendPageView = to.fullPath.replace(/#.*$/i, '') !== from.fullPath.replace(/#.*$/i, '');

        if (shouldSendPageView) {
            sendPageView(to, head);
        }

        lastPageViewPath = to.fullPath;
    });

    inject('gtm', {
        execute () {
            logger.debug('▷', ...arguments);
            window.dataLayer.push(...arguments);
        },
        sendPageView,
        sendGTMEvent,
    });
}

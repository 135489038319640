<template>
    <b-nav-item :id="menu.name" :href="menu.uri" class="peekage-nav-item after" :class="{
                    'only-device': menu.onlyDevice,
                    // 'active': isActive
                }"
                ref="activeNavItem"
    >
        <div class="after">
            <span class="font nav-text text-color p-x-large-20" :class="{'primary': !isTalkToExp}">
                {{ menu.name }}
            </span>
            <nuxt-link v-if="isTalkToExp" hreflang="en-ca" :to="menu.uri" class="fit-content btn sm primary d-flex font f-s-16 l-h-24 f-w-700" style="letter-spacing: -0.3px;">
                Talk to an expert
                <span class="icon arrow-right color onColor size sm m-l-4" />
            </nuxt-link>
        </div>
    </b-nav-item>
</template>

<script>
export default {
    props: {
        menu: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        isTalkToExp() {
            return this.menu.uri.includes('/contact?talk-to-an-expert=true');
        },
        isActive() {
            return this.$route.fullPath.toLowerCase() === this.menu.uri.toLowerCase();
        },
    },
    mounted() {
        this.anchorHandler();
    },
    methods: {
        anchorHandler () {
            if (process.client) {
                if (!this.menu.anchored) return;

                if (this.isActive) {
                    this.$refs.activeNavItem.classList.add('active');
                }
                try {
                    const step1 = this.menu.uri.replace('/#', '');
                    const step2 = step1.replace('/', '');
                    const element = document.querySelector(`#${step2}`);

                    if (element) {
                        const offsetTop = element.offsetTop;
                        window.addEventListener('scroll', (e) => {
                            const offsetScroll = window.scrollY;

                            if ((offsetScroll) >= offsetTop) {
                                this.$refs.activeNavItem.classList.add('active');
                            // this.$scrollTo(`#${step2}`);
                            } else if (offsetScroll < offsetTop) {
                                this.$refs.activeNavItem.classList.remove('active');
                            }
                        });
                    }
                } catch (error) {
                    // console.log(error);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.peekage-nav-item {
    .after{
                ::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-51%);
                    width: 90%;
                    height: 2px;
                    background-color: #F4F4F4;
                }
                :last-child{
                    ::after{
                        content: none;
                    }
                }

            }
    gap: 48px;
    min-height: 57px;
        .nav-text {
            // color: black;
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            letter-spacing: -0.3px;
            @media only screen and (min-width: 992px) {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                letter-spacing: -0.3px;
            }
        }

        .nav-link {
            outline: none;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 48px;
            &:hover {
                background-color: #FFF1F3;
            }
        }
        &:last-child {
            // border: unset;
        }

        &.active {
            color: #FF4F66;
            .nav-link {
                color: #FF4F66 !important;
            }
            &::after {
                background-color: #FF4F66 !important;
            }
        }
        .dropdown {
            &-menu {
                padding: 0px !important;
                border: unset;

                li {
                    margin-right: 12px;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }

            &-item {
                border-radius: 8px;
                padding: 0;
                // width: 1164px;
                white-space: normal !important;
                &:hover{
                    background-color: unset;
                }
                &:active {
                    // background-color: #000;
                }
            }
        }

        @media only screen and (min-width: 992px) {
            .after{
                ::after{
                    content: none;
                }
            }
            /* For desktop: */
            display: flex;
            justify-content: center;
            align-items: center;
            height: 72px;
            border-bottom: 2px solid transparent;
            position: relative;
            .dropdown {
                &-menu {
                    &.show {
                        border-radius: var(--Sizing-Space-500, 20px);
                        background: #FFF;
                        box-shadow: 0px 20px 32px 0px rgba(0, 0, 0, 0.12);
                    }
                }
            }
        }
    }
</style>

<template>
  <input-container
    class="select-container"
    :label="label"
    :classes="classes"
    :assistive="assistive"
    :no-assistive="noAssistive"
    :class="{ focused, disabled, invalid, small }">
    <template #container>
        <v-select
            :no-drop="noDrop"
            :value="selected"
            v-model="selected"
            ref="vselect"
            :loading="loading"
            :taggable="taggable"
            :closeOnSelect="closeOnSelect"
            :searchable="searchable"
            :placeholder="placeholder"
            :options="options"
            :class="{ small }"
            :clearable="clearable"
            :multiple="multiple"
            :clearSearchOnSelect="clearSearchOnSelect"
            :label="keyName"
            :reduce="reduce"
            :disabled="disabled"
            :pushTags="pushTags"
            @search="search"
            @open="focus"
            @close="blur"
            @option:selected="selectedOption"
        >

        <!-- <template #spinner="{ loading }">
          <div
            v-if="loading"
            style="border-left-color: rgba(88, 151, 251, 0.71)"
            class="vs__spinner"
          >
            The .vs__spinner class will hide the text for me.
          </div>
        </template> -->
          <template #option="option">
            <slot name="option-item" :option="option">
              <span>{{ option[keyName] }}</span>
            </slot>
          </template> 
          
          <template #selected-option="option">
            <slot name="selected-option" :option="option">
              <span>{{ option[keyName] }}</span>
            </slot>
          </template>           

          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="icon expand-more color primary"></span>
          </template>

        </v-select>
    </template>
  </input-container>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';

import InputContainer from './input-container.vue';
// import InputComponent from './input-component.vue';

export default {
  components: {
    InputContainer,
    // InputComponent,
    vSelect,
  },
  data() {
    return {
      focused: false,
      text: '',
      autocomplete: '',
    };
  },
  props: {
    noDrop: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearSearchOnSelect: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    noAssistive: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    clean: {
      type: Boolean,
      default: false,
    },
    value: {},
    label: {
      type: [String, Boolean],
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select...',
    },
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    classes: {
      type: String,
      default: 'p-b-32',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    keyName: {
      type: String,
      default: 'name',
    },
    valueName: {
      type: String,
      default: 'value',
    },
    pushTags: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default(option) {
        if (typeof option === 'object') {
          return option[this.valueName];
        }

        return option;
      },
    },
  },
  computed: {
    showCleanButton() {
      return !this.disabled && this.clean && this.text;
    },
    invalid() {
      return this.error;
    },
    selected: {
      get() {
        return this.value || '';
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    empty() {
      this.text = '';
    },
    selectedOption(option) {
      this.$emit('on-select', JSON.parse(JSON.stringify(option)));
    },
    search(search, loading) {
      // console.log(search, loading);
    },
  },
};
</script>

<style lang="scss">
.select {
    &-container {
        .input-body {
            overflow: unset !important;
            display: block !important;
            position: relative;
            .icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .v-select {
            border-radius: 8px;
            width: 100%;
            padding-left: 12px;
            height: 44px !important;

            &.vs--disabled {
              .vs__selected {
                color: inherit !important;
              }

              .vs__open-indicator {
                background-color: #AFAFAF !important;
              }
            }

            &.vs--single {
              &.vs--open {
                .vs__selected {
                  opacity: 1 !important;
                }
              }
            }

            &.vs--open {
              .vs__actions {
                  .vs__open-indicator {
                    transform: rotate(180deg);
                  }
              }
            }

            &.small {
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              height: 38px !important;
              .vs__dropdown-toggle {
                height: inherit !important;
              }
            }
            .vs__dropdown-toggle {
                padding: 0px;
                border-radius: 8px;
                height: inherit !important;
                border: unset;
            }

            .vs__selected-options {
                justify-content: center;
                padding: 0px;
                .vs__search {
                    opacity: 1 !important;
                    margin: 0px;
                    padding-left: 0px;
                    &::placeholder {
                        color: #757575;
                    }
                }

                .vs__selected {
                    color: #141414;
                    margin: 0px;
                    padding: 0px;
                    position: unset !important;
                }
            }

            .vs__actions {
                padding-right: 12px;
                padding-top: 0px !important;
                .vs__clear {
                    width: 24px;
                    height: 24px;
                }
                .vs__open-indicator {
                  cursor: pointer;
                  transition: all .0.5s;
                  transform: unset;
                  transition-timing-function: unset;
                  position: relative;
                  top: unset;
                }
            }

            .vs__dropdown-menu {
                border-radius: 8px;
                padding: 8px;
                box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
                background: #FFFFFF;
                top: calc(100% + 4px);
                &::-webkit-scrollbar {
                    width: 4px;
                    // padding-right: 8px;
                    &-track {
                        box-shadow: unset;
                        border-radius: 0;
                        padding-right: 8px;
                    }
                    &-thumb {
                        background: #AFAFAF;
                        border-radius: 4px;
                        padding-right: 8px;
                    }
                }
            }
            .vs__dropdown-option {
                min-height: 48px;
                padding: 14px 12px;
                white-space: normal;
                &--highlight {
                    background: #EEEEEE;
                    color: #141414;
                    padding: 14px 12px;
                    border-radius: 8px;
                }
            }
        }
    }
}
</style>

<template>
    <div class="box-gradient p-x-100 p-y-96 p-x-small-20 p-y-small-50">
        <div class="box-gradient-content">
            <slot name="content"></slot>
        </div>
        <div class="box-gradient-action m-t-small-24 m-t-large-24">
            <a class="btn tube inverse lg has-icon" :class="{'primary' : isPrimary, 'secondary' : !isPrimary}">
                {{ btnText }}
                <span class="m-l-4 icon size sm arrow-right" />
            </a>
        </div>
    </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'Book',
    },
    isPrimary: {
        type: Boolean,
        default: true
    }
  },
};
</script>

<style lang="scss" scoped>
.box-gradient {
    background: linear-gradient(92.5deg,#ff4f66 18.27%,#d80665 81.46%);
    display: block;
    justify-content: space-between;
    &-content {
        max-width: 664px;
        p {
            letter-spacing: -0.75px;
        }
    }
    &-action {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (min-width: 992px) {
        /* For desktop: */
        border-radius: 8px;
        display: flex;
        &-content {
            p {
                letter-spacing: -1px;
            }
        }
    }
}
</style>

<template>
    <component :is="tag" :class="classList">
        <slot></slot>
    </component>
</template>

<script>
import colors from '../colors.json';

export default {
  props: {
    tag: {
      type: String,
      default: 'h1',
    },
    color: {
      type: String,
      default: 'primary',
    },
    font: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    return {
      sizes: {
        'xxx-lg': 'xxx-lg',
        'xx-lg': 'xx-lg',
        'x-lg': 'x-lg',
        lg: 'lg',
        md: 'md',
        sm: 'sm',
        'x-sm': 'x-sm',
        // web font-family: 'GDSage-Bold', 'Roboto', sans-serif !important;
        'web-xxx-lg': 'web web-xxx-lg',
        'web-xx-lg': 'web web-xx-lg',
        'web-x-lg': 'web web-x-lg',
        'web-md': 'web web-md',
        'web-sm': 'web web-sm',
        'web-x-sm': 'web web-x-sm',
        'web-xx-sm': 'web web-xx-sm',
      },
      colors: { ...colors },
      classes: [],
    };
  },
  computed: {
    classList() {
      // default classes
      this.classes.push('heading font text-color');

      // props classes
      this.classes.push(this.sizes[this.size]);
      this.classes.push(this.colors[this.color]);
      this.classes.push(this.font);

      return this.classes.join(' ');
    },
  },
};
</script>

<template>
  <label class="checkbox-container" :class="{ inline, invalid }">
    <input type="checkbox" ref="checkbox" :name="name" :value="vData" class="checkbox-input" @input="handler"
      :checked="checked" :disabled="disabled" />
    <div class="checkbox-checkmark-container" :style="{
      minWidth: `${minWidth}px !important`,
      minHeight: `${minHeight}px !important`
    }">
      <span class="checkbox-checkmark"></span>
    </div>
    <span v-if="label" :class="labelClass" class="checkbox-label font f-s-16 l-h-20 f-w-500" v-html="label" />
  </label>
</template>

<script>
import findIndex from 'lodash/findIndex';

export default {
  computed: {
    invalid() {
      return this.error;
    },
  },
  props: {
    labelClass: {
      type: String,
      default: '',
    },
    singleValue: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: [String, Number],
      default: 48,
    },
    minHeight: {
      type: [String, Number],
      default: 48,
    },
    value: {},
    vData: {
      type: [String, Boolean, Object, Number],
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'input-name',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default(v) {
        return v;
      },
    },
    remove: {
      type: Function,
      default(v) {
        console.log(v, this.vData, this.reduce(this.vData))
        return v === this.reduce(this.vData);
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      array: [],
    };
  },
  watch: {
    vData() { },
  },
  methods: {
    handler() {
      const { checked } = this.$refs.checkbox;
      const vData = this.reduce(this.vData);

      if (checked) {
        if (this.singleValue) {
          this.$emit('input', vData);
          return;
        }
        this.$emit('input', [...this.value, vData]);
        return;
      }

      if (!checked) {
        if (this.singleValue) {
          // this.$emit('input', this.reduce(checked));
          this.$emit('input', checked);
          return;
        }
      }

      const v = [...this.value];
      const index = findIndex(v, this.remove);
      if (index !== -1) {
        v.splice(index, 1);
      }

      this.$emit('input', [...v]);
    },
  },
};
</script>
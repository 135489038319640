const uris = [
    {
        uri: '/about/enterprise-solutions',
        to: '/about',
        statusCode: 301,
    },
    {
        uri: '/about/solutions-enterprise',
        to: '/about',
        statusCode: 301,
    },
    {
        uri: '/about/contact',
        to: '/about',
        statusCode: 301,
    },
    {
        uri: '/about/faq',
        to: '/about',
        statusCode: 301,
    },
    {
        uri: '/about/solutions-sme',
        to: '/about',
        statusCode: 301,
    },
    {
        uri: '/about/d2c-brands-solutions',
        to: '/about',
        statusCode: 301,
    },
    {
        uri: '/about/consumer-apps',
        to: '/about',
        statusCode: 301,
    },
    {
        uri: '/consumer-apps/solutions-sme',
        to: '/consumer-apps',
        statusCode: 301,
    },
    {
        uri: '/consumer-apps/d2c-brands-solutions',
        to: '/consumer-apps',
        statusCode: 301,
    },
    {
        uri: '/consumer-apps/solutions-enterprise',
        to: '/consumer-apps',
        statusCode: 301,
    },
    {
        uri: '/consumer-apps/consumer-apps',
        to: '/consumer-apps',
        statusCode: 301,
    },
    {
        uri: '/consumer-apps/enterprise-solutions',
        to: '/consumer-apps',
        statusCode: 301,
    },
    {
        uri: '/consumer-apps/contact',
        to: '/consumer-apps',
        statusCode: 301,
    },
    {
        uri: '/consumer-apps/faq',
        to: '/consumer-apps',
        statusCode: 301,
    },
    {
        uri: '/solutions-sme',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/contact',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/contact?talk-to-an-expert=true',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/consumer-apps',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/faq',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/solutions-sme',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/d2c-brands-solutions',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/solutions-enterprise',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/d2c-brands-solutions/enterprise-solutions',
        to: '/d2c-brands-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/d2c-brands-solutions',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/solutions-enterprise',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/contact?talk-to-an-expert=true',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/faq',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/solutions-enterprise',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/consumer-apps',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/solutions-sme',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/contact',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/enterprise-solutions/enterprise-solutions',
        to: '/enterprise-solutions',
        statusCode: 301,
    },
    {
        uri: '/faq/enterprise-solutions',
        to: '/faq',
        statusCode: 301,
    },
    {
        uri: '/faq/consumer-apps',
        to: '/faq',
        statusCode: 301,
    },
    {
        uri: '/faq/solutions-sme',
        to: '/faq',
        statusCode: 301,
    },
    {
        uri: '/faq/contact',
        to: '/faq',
        statusCode: 301,
    },
    {
        uri: '/faq/solutions-enterprise',
        to: '/faq',
        statusCode: 301,
    },
    {
        uri: '/faq/faq',
        to: '/faq',
        statusCode: 301,
    },
    {
        uri: '/faq/d2c-brands-solutions',
        to: '/faq',
        statusCode: 301,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/contact',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/solutions-enterprise',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/consumer-apps',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/faq',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/d2c-brands-solutions',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/520863f2-6662-448f-b6cb-a5c7cf2a12ad/solutions-enterprise',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/520863f2-6662-448f-b6cb-a5c7cf2a12ad/d2c-brands-solutions',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/520863f2-6662-448f-b6cb-a5c7cf2a12ad/consumer-apps',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/520863f2-6662-448f-b6cb-a5c7cf2a12ad/solutions-sme',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/520863f2-6662-448f-b6cb-a5c7cf2a12ad/enterprise-solutions',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/520863f2-6662-448f-b6cb-a5c7cf2a12ad/faq',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/solutions-sme',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/520863f2-6662-448f-b6cb-a5c7cf2a12ad/contact',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/enterprise-solutions',
        error: true,
        statusCode: 410,
    },
    {
        uri: '/widget/765ba687-4b74-4f5e-bf8a-7e247f48cfd0/terms',
        error: true,
        statusCode: 410,
    },
];

export default function ({ route, redirect, error }) {
    try {
        const path = route.path;
        const i = uris.find((o) => { return o.uri === path; });
        if (i) {
            if (i.to) return redirect(i.statusCode, i.to);

            if (i.error) {
                const statusCode = i.statusCode;
                return error({ statusCode, message: 'Page Not Found' });
            }
        }
    } catch (error) {}
}

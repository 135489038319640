<template>
    <div class="image-container" :style="{
        width: `${width}`,
        minWidth: `${width}`,
        height: `${height}`,
        minHeight: `${height}`,
        backgroundImage: `url(${source})`,
        backgroundSize: size
    }"></div>
</template>

<script>
export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: '100px',
    },
    height: {
      type: [Number, String],
      default: '100px',
    },
    size: {
      type: String,
      default: 'contain',
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 12px;
    background-position: center;
    background-repeat: no-repeat;
    // background-size: contain;
    &.no-border {
      border: unset !important;
    }
}
</style>

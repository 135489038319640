<template>
  <input-container
    :label="label"
    :assistive="assistive"
    :no-assistive="noAssistive"
    :class="{ focused, disabled, invalid }">
    <template #container>
      <!-- <span class="icon search size sm m-r-4 m-l-12" v-if="search"></span> -->
      <InputComponent
        ref="input"
        :placeholder="placeholder"
        v-model="password"
        :type="inputType"
        :name="name"
        @focus="focus"
        @blur="blur"
      />
      <button type="button" class="btn fab fab-sm icon clear color primary m-r-8" v-if="showCleanButton" @click="clear"></button>
        <button
            type="button"
            class="btn fab fab-sm icon color primary m-r-12"
            :class="{
                'visible-off': !showPassword,
                'visible-on': showPassword,
            }"
            @click="show" />
    </template>
  </input-container>
</template>

<script>
import InputContainer from './input-container.vue';
import InputComponent from './input-component.vue';

export default {
  components: {
    InputContainer,
    InputComponent,
  },
  data() {
    return {
      focused: false,
      showPassword: false,
      password: '',
    };
  },
  props: {
    noAssistive: {
      type: Boolean,
      default: false,
    },
    clean: {
      type: Boolean,
      default: false,
    },
    value: {},
    label: {
      type: [String, Boolean],
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: [Boolean, String, Array],
      default: false,
    },
    classes: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    showCleanButton() {
      return !this.disabled && this.clean && this.value.length;
    },
    invalid() {
      return this.error.length;
    },
    inputType() {
      return this.showPassword ? 'text' : 'password';
    },
  },
  watch: {
    password(password) {
      this.$emit('input', password);
    },
  },
  methods: {
    focus(focus) {
      this.focused = focus;
    },
    blur(blur) {
      this.focused = blur;
    },
    clear() {
      this.$refs.input.clear();
    },
    show() {
      this.showPassword = !this.showPassword;
    },
  },
  mounted() {
    this.password = this.value;
  },
};
</script>
